<template>
    <section class="form-insert">
        <div class="container" v-if="getDatabase && !notStandardForm">
            <h2 class="form-insert__title"
                @click="edit"
                @focusout="getDatabase.title = $event.target.innerText"
            >{{getDatabase.title}}</h2>
            <p class="form-insert__txt"
               @click="edit"
               @focusout="getDatabase.subtitle = $event.target.innerText"
            >{{getDatabase.subtitle}}</p>
            <form class="form-insert__form" v-on:submit="sendForm">
                <div class="input-group">
                    <input v-model="form.name" type="text" name="name" placeholder="Имя">
                </div>
                <div class="input-group">
                    <input v-model="form.phone" type="number" name="phone" placeholder="Телефон">
                </div>
                <div class="input-group">
                    <input v-model="form.email" type="email" name="email" placeholder="E-mail">
                </div>
                <div class="form-insert__form-submit">
                    <button class="btn" type="submit">{{getDatabase.button}}</button>
                </div>
            </form>
        </div>
        <div class="container" v-if="notStandardForm">
            <h2 class="form-insert__title"
            >Получите информацию об открытии онлайн школы KIDSTART</h2>
            <p class="form-insert__txt"
               @click="edit"
               @focusout="getDatabase.subtitle = $event.target.innerText"
               v-if="1===2"
            >{{getDatabase.subtitle}}</p>
            <form class="form-insert__form" v-on:submit="sendForm">
                <div class="input-group">
                    <input v-model="form.name" type="text" name="name" placeholder="Имя">
                </div>
                <div class="input-group">
                    <input v-model="form.phone" type="number" name="phone" placeholder="Телефон">
                </div>
                <div class="input-group">
                    <input v-model="form.email" type="email" name="email" placeholder="E-mail">
                </div>
                <div class="form-insert__form-submit">
                    <button class="btn" type="submit">Получить</button>
                </div>
            </form>
        </div>
        <Modal
                v-show="modal"
                @close="modal = false"
                :isClose="true"
        >
            <template v-slot:body>
                <h5 class="modal-status">Заявка успешно отправлена!</h5>
                <h2 class="box-modal__title">Поделиться</h2>
                <p class="box-modal__txt">Мой ребенок записан на бесплатный урок по предпринимательству в школу KidStart</p>
                <div class="box-modal__soc">
                    <a target="_blank" href="https://vk.com/share.php?url=https://kidstart.online"><img src="@/assets/images/modal/vk.svg"></a>
                    <a target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=https://kidstart.online"><img src="@/assets/images/modal/fb.svg"></a>
                    <a target="_blank" href="https://twitter.com/intent/tweet?url=https://kidstart.online&text=Мой ребенок записан на бесплатный урок по предпринимательству в школу KidStart"><img src="@/assets/images/modal/tw.svg"></a>
                    <a target="_blank" href="https://connect.ok.ru/offer?url=https://kidstart.online&title=KidStart&description=Мой ребенок записан на бесплатный урок по предпринимательству в школу KidStart"><img src="@/assets/images/modal/ok.svg"></a>
                </div>
                <p class="box-modal__txt-gray">Кстати, за этот пост Вы получите
                    <span>50% скидку</span> если ребенок будет учиться у нас дальше! Не забудьте
                    сделать скриншот или фото после размещения и сохранить ссылку!
                </p>
            </template>
        </Modal>
    </section>
</template>

<script>
    import {mapGetters} from "vuex";
    import axios from 'axios'
    import querystring from 'querystring'
    import Modal from "./Modal";
    export default {
        name: "PromoBlock",
        components: {Modal},
        props: {
          notStandardForm: {
            type: Boolean,
            default: false,
            required: false
          },
        },
        data () {
            return {
                form: {
                    name: null,
                    email: null,
                    phone: null,
                    message: null
                },
                modal: false
            }
        },
        computed: {
            ...mapGetters([
                'isAUTH'
            ]),
            getDatabase () {
                return this.$root.database.promoBlock
            }
        },
        methods: {
            edit (val) {
                if (this.isAUTH) {
                    val.target.contentEditable = 'true'
                    val.target.focus()
                } else {
                    return false
                }
            },
            sendForm(e) {
                e.preventDefault()
                this.form.message = this.$route.path
                axios.post('https://mykidstart.ru/send.php', querystring.stringify(this.form))
                  .then(res => {
                    res.status === 200 ? this.modal = true : alert('Что-то пошло не так =( \n Обновите страницу')
                  })
                  .catch(() => alert('Что-то пошло не так =( \n Обновите страницу'))
            }
        }
    }
</script>

<style scoped>
    .modal-status {
        border-bottom: 1px solid red; display: inline-block; margin-bottom: 20px;
        position: absolute; right: 50px; top: 10px;
    }
    .box-modal__title {
        margin-top: 35px;
    }
</style>

<template>
    <main class="main-page">
      <Sceleton v-if="!getDatabase"/>
        <div v-if="getDatabase">
          <HeroCourses :courses="$route.params.id" />
          <div
            v-for="(cart, i) in getDatabase"
            :key="'cart-courses' + i + cart.id"
          >
            <slot v-if="cart.id === cartId">
              <section class="future" v-if="getDatabase">
                <div class="container">
                  <h2 class="future__title">{{cart.title}}</h2>
                  <div class="future-lists">
                    <div class="future-lists__item"
                         v-for="block in cart.block1"
                         :key="block + Math.floor(Math.random() * 999)"
                    >
                      <div @click="edit" @focusout="block.title = $event.target.innerText" class="future-lists__item-title">{{block.title}}</div>
                      <ul class="future-list">
                        <li class="future-list__item"
                            v-for="point in block.points"
                            :key="point.text"
                            @click="edit"
                            @focusout="point.text = $event.target.innerText"
                        >{{point.text}}</li>
                      </ul>
                    </div>
                  </div>
                  <div class="future-lists">
                    <div class="future-lists__item"
                         v-for="block in cart.block2"
                         :key="block + Math.floor(Math.random() * 999)"
                    >
                      <p class="future-lists__item-title" @click="edit" @focusout="block.title = $event.target.innerText">{{block.title}}</p>
                      <ul class="future-list future-list--check">
                        <li class="future-list__item"
                            v-for="point in block.points"
                            :key="point.text"
                            @click="edit"
                            @focusout="point.text = $event.target.innerText"
                        >{{point.text}}</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </section>
              <section class="how-happen" v-if="getDatabase">
                <div class="container">
                  <h2 class="how-happen__title" @click="edit" @focusout="cart.block3.title = $event.target.innerText">{{cart.block3.title}}</h2>
                  <p class="how-happen__subtitle" @click="edit" @focusout="cart.block3.subtitle = $event.target.innerText">{{cart.block3.subtitle}}</p>
                  <div class="happen-list">
                    <div class="happen-item"
                         v-for="point in cart.block3.points"
                         :key="point + Math.floor(Math.random() * 99)"
                    >
                      <div class="happen-item__img"> <img :src="require(`@/assets/images/how-happen/${point.src}`)" alt=""></div>
                      <p class="happen-item__txt"
                         @click="edit"
                         @focusout="point.title = $event.target.innerText"
                      >{{point.title}}</p>
                    </div>
                  </div>
                </div>
              </section>
              <section class="program" v-if="getDatabase">
                <div class="container">
                  <h2 class="program__title" @click="edit" @focusout="cart.block4.title = $event.target.innerText">{{cart.block4.title}}</h2>
                  <p class="program__subtitle" @click="edit" @focusout="cart.block4.subtitle = $event.target.innerText">{{cart.block4.subtitle}}</p>
                  <div style="max-height:1200px; height: auto;overflow-y: auto;">
                    <div class="program-block"
                         v-for="(block, i) in cart.block4.points"
                         :key="i + Math.floor(Math.random() * 9999)"
                    >
                      <div class="program-block__number"> <span>{{block.number}}</span></div>
                      <div class="program-block__right">
                        <div class="program-block__topics">
                          <div class="program-block__topics-title"
                               @click="edit"
                               @focusout="block.title = $event.target.innerText"
                          >{{block.title}}</div>
                          <div class="program-block__desk-toggler">
                            <div class="program-block__desk-toggler-item" :class="{'is-active': idProgram === el.id}" data-index="1"
                                 v-for="(el, i) in block.blocks"
                                 :key="el.id + i + Math.floor(Math.random() * 999)"
                                 @click="idProgram = el.id"
                                 @dblclick="edit"
                                 @focusout="el.title = $event.target.innerText"
                            >{{el.title}}</div>
                          </div>
                        </div>
                        <div class="program-block__contanet">
                          <div class="program-block__contanet-item is-active"
                               v-for="(el, i) in block.blocks"
                               :key="el.id + i + Math.floor(Math.random() * 999)"
                               v-show="el.id === idProgram"
                          >
                            <div class="program-block__contanet-title">
                              <p @click="edit" @focusout="el.text.title = $event.target.innerText">{{el.text.title}}</p>
                              <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path opacity="0.4" d="M11 7L6 2L1 7" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
                              </svg>
                            </div>
                            <div class="program-block__contanet-txt">
                              <p @click="edit" @focusout="el.text.subtitle = $event.target.innerText" v-html="el.text.subtitle"></p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="program-note">
                      <div class="program-note__img"> <img src="@/assets/images/program/lamp.svg" alt=""></div>
                      <div class="program-note__txt">
                        <p
                          @click="edit"
                          @focusout="cart.block4.programNote.title = $event.target.innerText"
                        >{{cart.block4.programNote.title}}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <PromoBlock/>
              <section class="why-choose">
                <div class="container" v-if="getDatabase">
                  <h2 class="why-choose__title"
                      @click="edit"
                      @focusout="cart.block5.title = $event.target.innerText"
                  >{{cart.block5.title}}</h2>
                  <div class="why-choose-list">
                    <div class="why-choose-list__item"
                         v-for="(point, i) in cart.block5.points"
                         :key="'block5' + i + Math.floor(Math.random() * 9999)+ Math.floor(Math.random() * 99)"
                    >
                      <div class="why-choose-list__item-img"> <img :src="require(`@/assets/images/how-happen/${point.src}`)" alt=""></div>
                      <p class="why-choose-list__item-txt"
                         @click="edit"
                         @focusout="point.title = $event.target.innerText"
                      >{{point.title}}</p>
                    </div>
                  </div>
                </div>
              </section>
              <section class="spec-prise">
                <div class="container">
                  <div class="spec-content">
                    <div class="spec-content__img"> <img src="@/assets/images/spec-prise/spec.svg" alt=""></div>
                    <div class="spec-content__price" v-if="getDatabase">
                      <div
                        @click="edit"
                        @focusout="cart.specialPrice.title = $event.target.innerText"
                        class="spec-content__price-title"
                      >{{cart.specialPrice.title}}</div>
                      <div class="spec-content__price-row">
                                    <span class="number"
                                          @click="edit"
                                          @focusout="cart.specialPrice.oldPrice = $event.target.innerText"
                                    >{{cart.specialPrice.oldPrice}}</span>
                        <span class="number"
                              @click="edit"
                              @focusout="cart.specialPrice.price = $event.target.innerText"
                        >{{cart.specialPrice.price}}</span><span>+ 1 ребёнок бесплатно</span>
                      </div>
                    </div>
                    <div class="spec-content__btn" style="cursor: pointer"> <a href="https://shkola-avtourist.getcourse.ru/kidstart_kurs_24" target="_blank" class="btn"><span class="btn__inner">Купить курс</span></a></div>
                  </div>
                </div>
              </section>
              <section class="reviews">
                <div class="container">
                  <h2 class="reviews__title">Отзывы</h2>
                  <div class="reviews-list" v-if="getDatabase">
                    <div class="reviews-carsd reviews-list__item"
                         v-for="(review, i) in cart.reviews"
                         :key="'reviews' + i + Math.floor(Math.random() * 9999) + Math.floor(Math.random() * 999)"
                    >
                      <div class="reviews-carsd__top">
                        <div class="reviews-carsd__img" v-if="1===3"> <img :src="require(`@/assets/images/reviews/${review.src}`)" alt=""></div>
                        <p class="reviews-carsd__name"
                           @click="edit"
                           @focusout="review.name = $event.target.innerText"
                        >{{review.name}}</p>
                      </div>
                      <div class="reviews-carsd__txt">
                        <p
                          @click="edit"
                          @focusout="review.review = $event.target.innerText"
                        >{{review.review}}</p>
                      </div>
                    </div>
                  </div>
                  <div class="banner-insert" v-if="getDatabase">
                    <div class="banner-insert__title"
                         @click="edit"
                         @focusout="cart.banner.title = $event.target.innerText"
                    >{{cart.banner.title}}</div>
                    <div class="banner-insert__txt"
                         @click="edit"
                         @focusout="cart.banner.subtitle = $event.target.innerText"
                    >{{cart.banner.subtitle}}</div>
                  </div>
                </div>
              </section>
              <section class="reviews">
                <div class="container">
                  <h2 class="reviews__title">Отзывы</h2>
                  <div class="reviews-video">
                    <p class="reviews-video__title">Расскажи, насколько тебе интересно?</p>
                    <div class="reviews-video__list">
                      <div class="reviews-video__list-item default-iframe"><img class="default-iframe__poster" :src="require(`@/assets/images/review-video${cart.reviewsVideo[0].imageUrl}`)" alt=""></div>
                      <div class="reviews-video__list-item default-iframe"><img class="default-iframe__poster" :src="require(`@/assets/images/review-video${cart.reviewsVideo[1].imageUrl}`)" alt=""></div>
                      <div class="reviews-video__list-item default-iframe"><img class="default-iframe__poster" :src="require(`@/assets/images/review-video${cart.reviewsVideo[2].imageUrl}`)" alt=""></div>
                    </div>
                  </div>
                  <div class="reviews-video">
                    <p class="reviews-video__title">Какие результаты получили ваши дети?</p>
                    <div class="reviews-video__list" style="justify-content: space-between">
                      <div v-if="1===2" class="reviews-video__list-item default-iframe">
                        <img v-if="cart.id !== 2 && cart.id !== 1" class="default-iframe__poster" :src="require(`@/assets/images/review-video${cart.reviewsVideo[3].imageUrl}`)"  alt="">
                      </div>
                      <div class="reviews-video__list-item default-iframe video-youtube">
                        <iframe
                          width="560"
                          height="315"
                          src="https://www.youtube.com/embed/d4Zx_ETMTAM"
                          title="YouTube video player"
                          frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowfullscreen></iframe>
                      </div>
                      <div class="reviews-video__list-item default-iframe video-youtube">
                        <iframe
                          width="560"
                          height="315"
                          src="https://www.youtube.com/embed/VLvDYE7HyNw"
                          title="YouTube video player"
                          frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowfullscreen></iframe>
                      </div>
                      <div class="reviews-video__list-item default-iframe video-youtube">
                        <iframe
                          width="560"
                          height="315"
                          src="https://www.youtube.com/embed/ZaFm_dIrsz4"
                          title="YouTube video player"
                          frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowfullscreen></iframe>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section class="metodology">
                <div class="container" v-if="getDatabase">
                  <h2 class="metodology__title"
                      @click="edit"
                      @focusout="cart.methodolog.title = $event.target.innerText"
                  >{{cart.methodolog.title}}</h2>
                  <p class="metodology__subtitle"
                     @click="edit"
                     @focusout="cart.methodolog.subtitle = $event.target.innerText"
                  >{{cart.methodolog.subtitle}}</p>
                  <div class="metodology-list">
                    <div class="metodology-list__item"
                         v-for="(el, i) in cart.methodolog.points"
                         :key="'methodolog' + i + Math.floor(Math.random() * 9999) + Math.floor(Math.random() * 99)"
                    >
                      <div class="metodology-list__item-img">
                        <img :src="require(`@/assets/images/metodology/${el.src}`)" alt="">
                      </div>
                      <div class="metodology-list__item-text">
                        <h4 class="metodology-list__item-name"
                            @click="edit"
                            @focusout="el.name = $event.target.innerText"
                        >{{el.name}}</h4>
                        <p class="metodology-list__item-txt"
                           @click="edit"
                           @focusout="el.text = $event.target.innerText"
                        >{{el.text}}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <Quiz/>
            </slot>
          </div>
          <Modal
            v-show="modal"
            @close="modal = false"
            :isClose="true"
          >
            <template v-slot:body>
              <div style="
                    color: #3ECF31;
                    font-weight: 800;
                    font-size: 28px;
                    line-height: 33px;
                    margin-bottom: 20px;
                ">Специальная цена</div>
              <div style="color: #FFFFFF;margin-bottom: 20px">
                <span style="opacity: 0.6;font-feature-settings: 'pnum' on, 'lnum' on;">23 300</span>
                <span style="font-size: 28px;font-feature-settings: 'pnum' on, 'lnum' on;margin: 0 10px">16 000</span>
                <span style="opacity: 0.6;font-feature-settings: 'pnum' on, 'lnum' on;">+ 1 ребёнок бесплатно</span>
              </div>
              <div class="modal-buy" style="display: flex;flex-direction: column">
                <input v-model="form.name" style="padding: 16px 13px; margin-bottom: 12px;font-size: 18px; background: white;" placeholder="Имя" type="text">
                <input v-model="form.city" style="padding: 16px 13px; margin-bottom: 12px;font-size: 18px; background: white;" placeholder="Город" type="text">
                <input v-model="form.phone" style="padding: 16px 13px; margin-bottom: 12px;font-size: 18px; background: white;" placeholder="Телефон" type="text">
                <input v-model="form.email" style="padding: 16px 13px; margin-bottom: 12px;font-size: 18px; background: white;" placeholder="E-mail" type="text">
              </div>
              <div class="btn" @click="sendForm" style="text-align: center;cursor: pointer">Купить курс</div>
            </template>
          </Modal>
          <Modal
            v-show="modalShare"
            @close="modalShare = false"
            :isClose="true"
          >
            <template v-slot:body>
              <h5 class="modal-status">Заявка успешно отправлена!</h5>
              <h2 class="box-modal__title">Поделиться</h2>
              <p class="box-modal__txt">Мой ребенок записан на бесплатный урок по предпринимательству в школу KidStart</p>
              <div class="box-modal__soc">
                <a target="_blank" href="https://vk.com/share.php?url=https://kidstart.online"><img src="@/assets/images/modal/vk.svg"></a>
                <a target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=https://kidstart.online"><img src="@/assets/images/modal/fb.svg"></a>
                <a target="_blank" href="https://twitter.com/intent/tweet?url=https://kidstart.online&text=Мой ребенок записан на бесплатный урок по предпринимательству в школу KidStart"><img src="@/assets/images/modal/tw.svg"></a>
                <a target="_blank" href="https://connect.ok.ru/offer?url=https://kidstart.online&title=KidStart&description=Мой ребенок записан на бесплатный урок по предпринимательству в школу KidStart"><img src="@/assets/images/modal/ok.svg"></a>
              </div>
              <p class="box-modal__txt-gray">Кстати, за этот пост Вы получите
                <span>50% скидку</span> если ребенок будет учиться у нас дальше! Не забудьте
                сделать скриншот или фото после размещения и сохранить ссылку!
              </p>
            </template>
          </Modal>
        </div>
    </main>
</template>

<script>
    import { mapGetters } from 'vuex'
    import HeroCourses from "../components/HeroCourses";
    import PromoBlock from "../components/PromoBlock";
    import Modal from "../components/Modal";
    import axios from "axios";
    import querystring from "querystring";
    import Quiz from "../components/Quiz";
    import Sceleton from "../components/Sceleton";
    export default {
        name: "Cart-Course",
        components: {Quiz, Modal, PromoBlock, HeroCourses, Sceleton},
        data () {
            return {
                isEnabled: true,
                idProgram: 1,
                form: {
                    name: null,
                    email: null,
                    phone: null,
                    message: null,
                    city: null
                },
                modal: false,
                modalShare: false
            }
        },
        computed: {
            ...mapGetters([
                'isAUTH'
            ]),
            getDatabase () {
                return this.$root.database.cartCourses
            },
            cartId () {
                return this.$route.path === '/cart-courses/7-8' ? 1
                    : this.$route.path === '/cart-courses/9-11' ? 2
                        : this.$route.path === '/cart-courses/12-14' ? 3
                            : this.$route.path === '/cart-courses/15-17' ? 4
                                : false
            }
        },
        methods: {
            edit (val) {
                if (this.isAUTH) {
                    val.target.contentEditable = 'true'
                    val.target.focus()
                } else {
                    return false
                }
            },
            sendForm(e) {
                e.preventDefault()
                this.form.message = this.$route.path
                this.modalShare = false
                axios.post('https://mykidstart.ru/send.php', querystring.stringify(this.form))
                  .then(res => {
                    if (res.status === 200) {
                      this.modalShare = true
                      this.modal = false
                    } else alert('Что-то пошло не так =( \n Обновите страницу')
                  })
                  .catch(() => alert('Что-то пошло не так =( \n Обновите страницу'))
            }
        }
    }
</script>

<style scoped lang="scss">
    .reviews-video__list-item {
    }
    .modal-buy {
        input {
            border: initial;
            border-bottom: 1px solid #ede7e7;
        }
    }
    .video-youtube {
      @media(max-width: 1000px) {margin-left: 0!important;}
    }
</style>

<template>
  <section class="quiz">
    <div class="container">
      <div>
        <div
          class="quiz-title"
          @click="edit"
          @focusout="getDatabase.title = $event.target.innerText"
        >{{getDatabase.title}}</div>
        <div
          class="quiz-subtitle"
          @click="edit"
          @focusout="getDatabase.subtitle = $event.target.innerText"
        >{{getDatabase.subtitle}}</div>
      </div>
      <div class="quiz-img"><img src="../assets/images/quiz.png" alt=""></div>
      <div style="display: flex;flex-direction: column">
        <p v-if="isAUTH" style="font-size: 10px">Для ред. кнопок, двойное нажатие на текст кнопки</p>
        <a class="btn" :href="getDatabase.href" target="_blank">
          <span
            @dblclick="edit"
            @focusout="getDatabase.button = $event.target.innerText"
          >
          {{getDatabase.button}}
          </span>
        </a>
        <input
          v-if="isAUTH"
          @focusout="getDatabase.href = $event.target.value"
          style="height: 10px"
          :value="getDatabase.href"
          type="text"
        >
      </div>
    </div>
  </section>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "Quiz",
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters([
      'isAUTH'
    ]),
    getDatabase () {
      return this.$root.database.quiz
    }
  },
  methods: {
    edit (val) {
      if (this.isAUTH) {
        val.target.contentEditable = 'true'
        val.target.focus()
      } else {
        return false
      }
    },
  }
}
</script>

<style scoped lang="scss">
.quiz {
  padding: 50px 0;
  .container {
    background: #F6F1FD;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 130px;
    @media (max-width: 1300px) {
      flex-direction: column;
      height: inherit;
      padding: 30px;
    }
    .quiz-title {
      font-weight: 800;
      font-size: 28px;
      line-height: 33px;
      margin-bottom: 20px;
      color: rgba(0, 0, 0, 0.75);
      @media (max-width: 1300px) {
        font-size: 22px;
        line-height: 23px;
        margin-bottom: 20px;
      }
    }
    .quiz-subtitle {
      color: #9974D5;
      font-weight: 600;
      font-size: 18px;
      line-height: 21px;
      @media (max-width: 1300px) {
        text-align: center;
        margin-bottom: 20px;
      }
    }
    .quiz-img {
      position: relative;
      width: 100px;
      height: 130px;
      @media (max-width: 1300px) {
        height: 200px;
      }
      img {
        position: absolute;
        top: -30px;
        left: 0;
        @media (max-width: 1300px) {
          top: 0;
        }
        @media (max-width: 650px) {
          left: -30px;
        }
      }
    }
  }
}
</style>

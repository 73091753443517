<template>
    <main class="main-page main-article">
        <Sceleton v-if="!getDatabase"/>
        <slot v-if="getDatabase">
            <section class="article">
                <div class="container"
                     v-for="(article, i) in getDatabase"
                     :key="'article-' + article.id + i + Math.floor(Math.random() * 99999)"
                >
                    <div class="article-content" v-if="article.id === Number($route.path.replace(/\/article\//g, ''))">
                        <h2
                                @click="edit"
                                @focusout="article.title = $event.target.innerText"
                        >{{article.title}}</h2>
                        <div
                                v-for="(content, index) in article.content"
                                :key="'article-content' + article.id + index + Math.floor(Math.random() * 99999)"
                        >
                            <p
                                    @click="edit"
                                    @focusout="content.text = $event.target.innerText"
                                    v-html="content.text"
                            >{{content.text}}</p>
                            <div v-if="!content.url || (content.url && !content.url.length)" class="image-wrap" style="position: relative">
                              <img v-if="content.image.indexOf('https') !== -1" :src="content.image" alt="">
                              <span v-if="content.image.indexOf('https') !== -1 && isAUTH" class="image-delete">
                                <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                                  <path fill="currentColor" d="M19,4H15.5L14.5,3H9.5L8.5,4H5V6H19M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19Z" />
                                </svg>
                              </span>
                              <span v-if="content.image.indexOf('https') !== -1 && isAUTH" class="image-edit">
                                <label class="upload-label">
                                  <h5 class="filename"></h5>
                                  <input hidden @change="upload($event, i, index)" type="file" class="file" name="">
                                  <span class="btn">Изменить</span>
                                </label>
                              </span>
                              <img v-if="content.image.indexOf('https') === -1" :src="require(`@/assets/images/article/${content.image}`)" alt="">
                            </div>
                            <div v-else class="image-wrap" style="position: relative">
                              <iframe style="width: 100%; height: 400px" :src="content.url" frameborder="0"></iframe>
                            </div>
                            <PromoBlock v-if="(article.id === 32 || article.id === 34) && index === 1" not-standard-form class="promo-block" />
                            <p
                                    @click="edit"
                                    @focusout="content.text_2 = $event.target.innerText"
                                    :template="content.text_2"
                                    v-html="content.text_2"
                            ></p>
                        </div>
                    </div>
                </div>
            </section>
            <div class="article__pagination" style="margin-bottom: 30px">
                <router-link :to="{name: 'Article', params: {id: Number($route.path.replace(/\/article\//g, '')) - 1}}" :class="{'disable-prev-button': $route.params.id <= 1}" class="article__pagination-prev"><span class="desk">Предыдущая статья</span><span class="mob">Пред</span></router-link>
                <router-link :to="{name: 'Article', params: {id: Number($route.path.replace(/\/article\//g, '')) + 1}}" class="article__pagination-next"><span class="desk">Следующая статья</span><span class="mob">След</span></router-link>
            </div>
        </slot>
        <PromoBlock/>
    </main>
</template>

<script>
    import {mapGetters} from "vuex";
    import Sceleton from "../components/Sceleton";
    import PromoBlock from "../components/PromoBlock";
    import { getStorage, ref, uploadBytesResumable, getDownloadURL, deleteObject } from "firebase/storage";
    export default {
        name: "Article",
        components: {PromoBlock, Sceleton},
        computed: {
            ...mapGetters([
                'isAUTH'
            ]),
            getDatabase () {
                return this.$root.database.articles
            }
        },
        methods: {
          edit (val) {
              if (this.isAUTH) {
                  val.target.contentEditable = 'true'
                  val.target.focus()
              } else {
                  return false
              }
          },
          upload (event, index, imageIndex) {
            const file = event.currentTarget.files[0]
            const storage = getStorage();

            // Create the file metadata
            /** @type {any} */
            const metadata = {
              contentType: 'image/jpeg'
            };

            // Upload file and metadata to the object 'images/mountains.jpg'
            const storageRef = ref(storage, 'articles/' + file.name);
            const uploadTask = uploadBytesResumable(storageRef, file, metadata);

            // Listen for state changes, errors, and completion of the upload.
            uploadTask.on('state_changed',
              (snapshot) => {
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log('Upload is ' + progress + '% done');
                switch (snapshot.state) {
                  case 'paused':
                    console.log('Upload is paused');
                    break;
                  case 'running':
                    console.log('Upload is running');
                    break;
                }
              },
              (error) => {
                // A full list of error codes is available at
                // https://firebase.google.com/docs/storage/web/handle-errors
                switch (error.code) {
                  case 'storage/unauthorized':
                    // User doesn't have permission to access the object
                    break;
                  case 'storage/canceled':
                    // User canceled the upload
                    break;

                  // ...

                  case 'storage/unknown':
                    // Unknown error occurred, inspect error.serverResponse
                    break;
                }
              },
              () => {
                // Upload completed successfully, now we can get the download URL
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                  if (this.getDatabase[index].content[imageIndex].image) {
                    deleteObject(ref(storage, this.getDatabase[index].content[imageIndex].image)).then(() => {
                      this.getDatabase[index].content[imageIndex].image = downloadURL
                      console.log('File deleted successfully')
                    }).catch((error) => {
                      console.log('Uh-oh, an error occurred!' + error)
                    });
                  }
                }).then(() => {
                  console.log('File deleted successfully')
                }).catch((error) => {
                  console.log('Uh-oh, an error occurred!' + error)
                  getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    this.getDatabase[index].content[imageIndex].image = downloadURL
                  })
                });
              }
            );
          }
        }
    }
</script>

<style lang="scss">
    .disable-prev-button {
        pointer-events: none;
        color: black;
        opacity: .5;
    }
    .article-content div p a {
        color: #9974d5!important;
    }
    .promo-block {
      h2 {
        color: white;
      }
    }
    .image-wrap {
      .image-delete {
        top: 10px;
        right: 10px;
        position: absolute;
        cursor: pointer;
        background: #efefef;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 3px;
        border-radius: 50%;
        border: 1px solid #dedede;
        &:hover {
          transform: scale(1.1);
          border: none;
        }
      }
      .image-edit {
        background: white;
        position: absolute;
        top: calc(50% - 0px);
        left: calc(50% - 45px);
        z-index: 200;
        text-align: center;
        border-radius: 8px;
        cursor: pointer;
      }
    }
</style>

<template>
  <main class="main-page webinar">
    <Sceleton v-if="!getDatabase"/>
    <div v-else class="container">
      <div
        v-for="(webinar, i) in getDatabase"
        :key="'webinar-' + webinar.id + i + Math.floor(Math.random() * 99999)"
      >
        <div v-if="webinar.visible || isAUTH">
          <span v-if="isAUTH">Номер вебинара: {{webinar.id}}</span>
          <div class="webinar-name"
               @click="edit"
               @focusout="webinar.name = $event.target.innerText"
          >{{ webinar.name }}</div>
          <div class="webinar-wrap">
            <div class="webinar-media-wrap">
              <div class="image-wrap" style="position: relative">
                <img v-if="webinar.image && !webinar.url" :src="webinar.image" alt="">
                <iframe v-else-if="webinar.url" :src="webinar.url" />
                <img v-else src="../assets/images/no_image-min.jpg" alt="">
                <input v-if="isAUTH" v-model="url" @change="addUrl(i)" style="margin-top: 20px" placeholder="Указать ссылку на видео">
                <p v-if="isAUTH" style="opacity: .7; font-style: italic; font-size: 10px">*Если указать ссылку на видео, то загруженная картинка не будет отображаться</p>
                <p v-if="isAUTH" style="opacity: .7; font-style: italic; font-size: 10px">*Ссылки указываются с ютуба в виде "https://youtu.be/JNY6UapuaoA"</p>
                <p v-if="isAUTH" style="opacity: .7; font-style: italic; font-size: 10px">*Чтобы заменить видео на картинку, оставьте поле ввода пустым и сохраните изменения</p>
                <span v-if="isAUTH && !webinar.url" class="image-delete">
                  <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M19,4H15.5L14.5,3H9.5L8.5,4H5V6H19M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19Z" />
                  </svg>
                </span>
                <span v-if="isAUTH && !webinar.url" class="image-edit">
                  <label class="upload-label">
                    <input hidden @change="upload($event, i, index)" type="file" class="file" name="">
                    <span class="btn">Изменить</span>
                  </label>
                </span>
              </div>
            </div>
            <div class="webinar-text-wrap">
              <div class="webinar-text--title"
                   @click="edit"
                   @focusout="webinar.title = $event.target.innerText"
              >{{webinar.title}}</div>
              <div class="webinar-text--subtitle"
                   @click="edit"
                   @focusout="webinar.subtitle = $event.target.innerText"
                   v-html="webinar.subtitle"
              ></div>
              <div class="webinar-text--text"
                   @click="edit"
                   @focusout="webinar.text = $event.target.innerText"
                   v-html="webinar.text"
              ></div>

              <div v-if="!webinar.button_url" style="display: flex; justify-content: end; align-items: center">
                <button class="webinar-text--button"
                        @dblclick="edit"
                        @focusout="webinar.button = $event.target.innerText"
                        :class="{'disable_btn':!webinar.btn_status}"
                >
                  {{webinar.button}}
                </button>
                <span v-if="isAUTH" style="margin-top: 20px" @click.prevent="webinar.btn_status = !webinar.btn_status"><Switches :switches="webinar.btn_status"/></span>
              </div>
              <div v-else style="display: flex; justify-content: end; align-items: center">
                <a
                  :href="webinar.button_url"
                  target="_blank"
                  style="margin-left: auto;display: table"
                  class="webinar-text--button"
                  :class="{'disable_btn':!webinar.btn_status}"
                  @dblclick="edit"
                  @focusout="webinar.button = $event.target.innerText"
                >
                  {{webinar.button}}
                </a>
                <span v-if="isAUTH" style="margin-top: 20px" @click.prevent="webinar.btn_status = !webinar.btn_status"><Switches :switches="webinar.btn_status"/></span>
              </div>
              <input
                v-if="isAUTH"
                :class="{'disable_btn':!webinar.btn_status}"
                v-model="webinar.button_url"
                style="margin-left: auto;margin-right: 10px; margin-top: 10px; display: table"
                type="text"
                placeholder="Указать ссылку на кнопку"
              >
              <p v-if="isAUTH" style="margin-left: auto;margin-right: 10px; margin-top: 10px; display: table; font-style: italic; opacity: 0.4; font-size: 12px">Если указать ссылку, то кнопку будет вести по этой ссылке</p>
              <div v-if="isAUTH" style="display: flex; align-items: center;max-width: 20%; min-width: 320px; margin-left: auto; margin-top: 20px">
                <div>Видимость: {{!webinar.visible && 'cкрыто' || 'видно всем'}}</div>
                <div style="margin-left: auto" @click.prevent="webinar.visible = !webinar.visible"><Switches :switches="webinar.visible"/></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="isAUTH" class="new-webinar">
        <button @click="addWebinar" class="btn">Добавить новый вебинар</button>
      </div>
    </div>
  </main>
</template>

<script>
import Sceleton from '../components/Sceleton'
import {mapGetters} from "vuex";
import {deleteObject, getDownloadURL, getStorage, ref, uploadBytesResumable} from "firebase/storage";
import Switches from "../components/Switches";
export default {
  name: "Webinar",
  components: {Switches, Sceleton},
  data() {
    return {
      url: ''
    }
  },
  computed: {
    ...mapGetters([
      'isAUTH'
    ]),
    getDatabase () {
      return this.$root.database.webinar
      // https://youtu.be/JNY6UapuaoA
    }
  },
  mounted() {
    setTimeout(()=>{
      this.url = this.getDatabase[Number(this.$route.params.id)].url
    }, 1000)
  },
  methods: {
    edit (val) {
      if (this.isAUTH) {
        val.target.contentEditable = 'true'
        val.target.focus()
      } else {
        return false
      }
    },
    addUrl (index) {
      this.getDatabase[index].url = this.url.replace('https://youtu.be/', 'https://www.youtube.com/embed/')
    },
    upload (event, index, imageIndex) {
      const file = event.currentTarget.files[0]
      const storage = getStorage();

      // Create the file metadata
      /** @type {any} */
      const metadata = {
        contentType: 'image/jpeg'
      };

      // Upload file and metadata to the object 'images/mountains.jpg'
      const storageRef = ref(storage, 'articles/' + file.name);
      const uploadTask = uploadBytesResumable(storageRef, file, metadata);

      // Listen for state changes, errors, and completion of the upload.
      uploadTask.on('state_changed',
        (snapshot) => {
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log('Upload is ' + progress + '% done');
          switch (snapshot.state) {
            case 'paused':
              console.log('Upload is paused');
              break;
            case 'running':
              console.log('Upload is running');
              break;
          }
        },
        (error) => {
          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          switch (error.code) {
            case 'storage/unauthorized':
              // User doesn't have permission to access the object
              break;
            case 'storage/canceled':
              // User canceled the upload
              break;

            // ...

            case 'storage/unknown':
              // Unknown error occurred, inspect error.serverResponse
              break;
          }
        },
        () => {
          // Upload completed successfully, now we can get the download URL
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            if (this.getDatabase[index].content[imageIndex].image) {
              deleteObject(ref(storage, this.getDatabase[index].content[imageIndex].image)).then(() => {
                this.getDatabase[index].content[imageIndex].image = downloadURL
                console.log('File deleted successfully')
              }).catch((error) => {
                console.log('Uh-oh, an error occurred!' + error)
              });
            }
          }).then(() => {
            console.log('File deleted successfully')
          }).catch((error) => {
            console.log('Uh-oh, an error occurred!' + error)
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              this.getDatabase[index].content[imageIndex].image = downloadURL
            })
          });
        }
      );
    },
    addWebinar () {
      this.getDatabase.push({
        button:"Записаться",
        btn_status: true,
        id: this.getDatabase.length,
        image:"",
        name:"Бесплатный вебинар KIDSTART",
        subtitle:"Бесплатный вебинар для родителей",
        text:"16 ноября 2022 года 17.00.<br>Ведущий: Евгений Шельмин. Серийный предприниматель. Инвестор. Ментор школы Kidstart.<br>Что будет: Разберем ТОП 10 проблем воспитания",
        title:"Как воспитать лидера",
        url:"https://www.youtube.com/embed/JNY6UapuaoA",
        visible:true
      })
    }
  }
}
</script>

<style scoped lang="scss">
.webinar .container {
  padding-top: 100px;
  padding-bottom: 50px;
  .new-webinar {
    display: flex;
    padding: 40px;
    justify-content: center;
    background-color: #c9c9c9;
    border-radius: 5px;
    margin-bottom: 40px;
  }
  .webinar-name {
    font-weight: 800;
    font-size: 44px;
    line-height: 52px;
    color: #5e4a7e;
    margin-bottom: 20px;
    position: relative;
  }
  .webinar-wrap {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 40px;
    @media (max-width: 900px) {
      grid-template-rows: 1fr;
      grid-template-columns: 1fr;
    }
    .webinar-media-wrap {
      .image-wrap {
        position: relative;
        display: flex;
        flex-direction: column;
        img, iframe {
          max-width: 80%;
          margin: 0 auto;
        }
        iframe {
          width: 100%;
          height: 300px;
        }
        .image-delete {
          top: 10px;
          right: 10px;
          position: absolute;
          cursor: pointer;
          background: #efefef;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 3px;
          border-radius: 50%;
          border: 1px solid #dedede;
          &:hover {
            transform: scale(1.1);
            border: none;
          }
        }
        .image-edit {
          background: white;
          position: absolute;
          top: calc(50% - 0px);
          left: calc(50% - 45px);
          z-index: 200;
          text-align: center;
          border-radius: 8px;
          cursor: pointer;
        }
      }
    }
    .webinar-text-wrap {
      .webinar-text--title{
        font-weight: 800;
        font-size: 24px;
        color: #5e4a7e;
        opacity: 0.8;
      }
      .webinar-text--subtitle{
        margin-bottom: 20px;
        font-size: 16px;
        opacity: 0.5;
      }
      .webinar-text--text{
        opacity: .8;
        font-size: 15px;
      }
      .webinar-text--button {
        background: #3ecf31;
        border-radius: 4px;
        padding: 13.5px 20px;
        color: #fff;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 21px;
        border: 1px solid #3ecf31;
        cursor: pointer;
        margin-left: auto;
        margin-top: 25px;
        margin-right: 25px;
        display: flex;
        &:hover {
          background: #fff;
          color: #3ecf31;
        }
      }
    }
  }
}
.disable_btn {
  pointer-events: none;
  opacity: 0.4;
}
</style>

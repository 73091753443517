<template>
    <main class="main-page main-article container">
      <Sceleton v-if="!getDatabase"/>
      <div class="articles">
        <router-link :to="{name: 'Article', params: {id: article.id}}" class="article-all"
             v-for="(article, i) in getDatabase"
             :key="article.id + '-' + i"
        >
          <div v-if="article.content[0].image.indexOf('https') !== -1" class="article-all-back-img"
               :style="{
                  backgroundImage: `url(${article.content[0].image})`
               }"
          ></div>
          <div v-else class="article-all-back-img"
               :style="{
                  backgroundImage: `url(${require(`@/assets/images/article/${article.content[0].image}`)})`
               }"
          ></div>
          <div class="article-text">
            <div class="article-title">{{article.title}}</div>
            <div class="article-subtitle truncate" v-html="article.content[0].text"></div>
          </div>
        </router-link>
        <div
          v-if="isAUTH"
          class="btn"
          @click="addNewArticle"
          style="display: flex; align-items: center; width: 100%; justify-content: center; height: 60px; cursor: pointer"
        >
          Добавить новую статью
          <svg style="width:24px;height:24px" viewBox="0 0 24 24">
            <path fill="currentColor" d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
          </svg>
        </div>
      </div>
    </main>
</template>

<script>
    import {mapGetters} from "vuex";
    import Sceleton from "../components/Sceleton";
    import readDB from '@/components/read'
    export default {
        name: "Article",
        components: {Sceleton},
        data() {
          return {
            modal: false,
            article: {
              title: null
            }
          }
        },
        computed: {
            ...mapGetters([
                'isAUTH'
            ]),
            getDatabase () {
                return this.$root.database.articles
            }
        },
        methods: {
            edit (val) {
                if (this.isAUTH) {
                    val.target.contentEditable = 'true'
                    val.target.focus()
                } else {
                    return false
                }
            },
          addNewArticle () {
              let newArticle = {
                content: [
                  {
                    image: 'https://mykidstart.ru/img/article8-1.552054a6.jpg',
                    text: 'Сайт рыбатекст поможет дизайнеру, верстальщику, вебмастеру сгенерировать несколько абзацев более менее осмысленного текста рыбы на русском языке, а начинающему оратору отточить навык публичных выступлений в домашних условиях',
                    text_2: ' При создании генератора мы использовали небезизвестный универсальный код речей. Текст генерируется абзацами случайным образом от двух до десяти предложений в абзаце, что позволяет сделать текст более привлекательным и живым для визуально-слухового восприятия'
                  },
                  {
                    image: 'https://mykidstart.ru/img/article8-1.552054a6.jpg',
                    text: 'Сайт рыбатекст поможет дизайнеру, верстальщику, вебмастеру сгенерировать несколько абзацев более менее осмысленного текста рыбы на русском языке, а начинающему оратору отточить навык публичных выступлений в домашних условиях',
                    text_2: ' При создании генератора мы использовали небезизвестный универсальный код речей. Текст генерируется абзацами случайным образом от двух до десяти предложений в абзаце, что позволяет сделать текст более привлекательным и живым для визуально-слухового восприятия'
                  }
                ],
                id: this.getDatabase.length + 1,
                title: 'Новая статья'
              }
              this.getDatabase.push(newArticle)
              this.writeDB()
          },
          writeDB () {
            readDB.writeUserData(this.$root.database).then(() => {
              console.log(true)
            }, () => {
              console.log(false)
            })
          },
        }
    }
</script>

<style scoped lang="scss">
  .articles {
    display: flex;
    flex-wrap: wrap;
    padding-top: 124px;
    padding-bottom: 60px;
    .article-all {
      display: flex;
      min-width: 50%;
      overflow: hidden;
      margin: 20px 0;
      .article-all-back-img {
        width: 200px;
        height: 100px;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
      }
      .article-text {
        margin-left: 20px;
        color: rgba(0,0,0, .75);
        width: 300px;
        display: flex;
        flex-wrap: wrap;
        .article-title {
          font-weight: bold;
        }
        .article-subtitle {
        }
      }
    }
    .truncate {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      line-height: 20px;
      max-height: 60px;
      white-space: inherit;
    }
  }
</style>

<template>
    <section class="hero hero-courses">
        <div class="container hero__container">
            <div class="hero-info" v-if="getDatabase">
                <h2 class="hero__title"
                    @click="edit"
                    @focusout="getDatabase.title = $event.target.innerText"
                >{{getDatabase.title}}</h2>
                <p class="hero__txt hero__txt--bg"
                   @click="edit"
                   @focusout="getDatabase.subtitle = $event.target.innerText"
                >{{getDatabase.subtitle}} {{courses.length && courses || ''}} лет</p>
            </div>
            <div class="hero-img">
                <div class="hero-img__item"> <img :src="require(`../assets/images/hero/${this.$route.path.replace(/\/cart-courses\//g, '')}/1.jpg`)" alt=""></div>
                <div class="hero-img__item"> <img :src="require(`../assets/images/hero/${this.$route.path.replace(/\/cart-courses\//g, '')}/2.jpg`)" alt=""></div>
            </div>
        </div>
    </section>
</template>

<script>
    import {mapGetters} from "vuex";
    export default {
        name: "HeroCourses",
        props: {
            courses: String
        },
        computed: {
            ...mapGetters([
                'isAUTH'
            ]),
            getDatabase () {
                return this.$root.database.heroCourses
            }
        },
        methods: {
            edit (val) {
                if (this.isAUTH) {
                    val.target.contentEditable = 'true'
                    val.target.focus()
                } else {
                    return false
                }
            }
        }
    }
</script>

<style scoped>

</style>

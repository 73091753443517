<template>
  <div class="switches">
    <label><input v-model="localeSwitches" type="checkbox" class="ios-switch bigswitch" checked />
      <div>
        <div></div>
      </div>
    </label>
  </div>
</template>

<script>
export default {
  name: "switches",
  data() {
    return {
      localeSwitches: this.switches
    }
  },
  props: {
    switches: Boolean
  }
}
</script>

<style scoped lang="scss">
.switches {
  width: 70px;
  display: flex;
  justify-content: center;
  input {width: initial}
  input[type="checkbox"] {
    position: absolute;
    opacity: 0;
  }

  /* Normal Track */
  input[type="checkbox"].ios-switch + div {
    vertical-align: middle;
    width: 40px;	height: 20px;
    border-radius: 999px;
    background-color: #565656;
    -webkit-transition-duration: .4s;
    -webkit-transition-property: background-color, box-shadow;
    box-shadow: inset 0 0 0 0px rgba(0,0,0,0.4);
    margin: 0 2.5em 0 2.5em;
  }

  /* Checked Track (Blue) */
  input[type="checkbox"].ios-switch:checked + div {
    width: 40px;
    background-position: 0 0;
    background-color: #3b89ec;
    box-shadow: inset 0 0 0 10px rgba(59,137,259,1);
  }
  /* Big Track */
  input[type="checkbox"].bigswitch.ios-switch + div {
    width: 48px;	height: 24px;
  }

  /* Normal Knob */
  input[type="checkbox"].ios-switch + div > div {
    float: left;
    width: 18px; height: 18px;
    border-radius: inherit;
    background: #ffffff;
    -webkit-transition-timing-function: cubic-bezier(.54,1.85,.5,1);
    -webkit-transition-duration: 0.4s;
    -webkit-transition-property: transform, background-color, box-shadow;
    -moz-transition-timing-function: cubic-bezier(.54,1.85,.5,1);
    -moz-transition-duration: 0.4s;
    -moz-transition-property: transform, background-color;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3), 0px 0px 0 1px rgba(0, 0, 0, 0.4);
    pointer-events: none;
    margin-top: 2px;
    margin-left: 4px;
  }
  /* Big Knob */
  input[type="checkbox"].bigswitch.ios-switch + div > div {
    width: 16px; height: 16px;
    margin-top: 4px;
  }

  /* Checked Big Knob (Blue Style) */
  input[type="checkbox"].bigswitch.ios-switch:checked + div > div {
    -webkit-transform: translate3d(25px, 0, 0);
    -moz-transform: translate3d(16px, 0, 0);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3), 0px 0px 0 1px rgba(8, 80, 172,1);
  }

  /* Green Knob */
  input[type="checkbox"].green.ios-switch:checked + div > div {
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3), 0 0 0 1px rgba(0, 162, 63,1);
  }
}
</style>

<template>
    <div class="login">
        <h1 v-if="!auth">Войти</h1>
        <h1
            v-if="auth"
            style="display: flex;align-items: center; justify-content: center; padding: 10px;border: 1px solid #494949;cursor: pointer"
            @click="LOGOUT"
        >Выйти
            <svg style="width:34px;height:34px; margin-left: 15px" viewBox="0 0 24 24">
                <path fill="currentColor" d="M14.08,15.59L16.67,13H7V11H16.67L14.08,8.41L15.5,7L20.5,12L15.5,17L14.08,15.59M19,3A2,2 0 0,1 21,5V9.67L19,7.67V5H5V19H19V16.33L21,14.33V19A2,2 0 0,1 19,21H5C3.89,21 3,20.1 3,19V5C3,3.89 3.89,3 5,3H19Z" />
            </svg>
        </h1>
        <div v-if="!auth" id="wrapper">
            <form id="signin" method="" action="" autocomplete="off">
                <input v-model="form.login" type="text" id="user" name="email" placeholder="Логин" />
                <input v-model="form.pass" type="password" id="pass" name="pass" placeholder="Пароль" />
                <button @click.prevent="submit" type="submit">
                    <svg style="width:24px;height:24px;margin-top: 5px" viewBox="0 0 24 24">
                        <path fill="currentColor" d="M7 14C5.9 14 5 13.1 5 12S5.9 10 7 10 9 10.9 9 12 8.1 14 7 14M12.6 10C11.8 7.7 9.6 6 7 6C3.7 6 1 8.7 1 12S3.7 18 7 18C9.6 18 11.8 16.3 12.6 14H16V18H20V14H23V10H12.6Z" />
                    </svg>
                </button>
                <p v-if="1 === 2">forgot your password? <a href="#">click here</a></p>
            </form>
        </div>
    </div>
</template>

<script>
    import { mapActions } from 'vuex'
    export default {
        name: "login",
        data () {
            return {
                form: {
                    login: null,
                    pass: null,
                    error: null
                }
            }
        },
        computed: {
            auth () {
                return localStorage.user
            }
        },
        methods: {
            ...mapActions([
                'LOGIN',
                'LOGOUT'
            ]),
            async submit () {
                const formData = {
                    login: this.form.login,
                    pass: this.form.pass
                }
                await this.LOGIN(formData)
            }
        },
        mounted() {
        }
    }
</script>

<style scoped lang="scss">
    .login {
        background: rgb(52, 56, 61);
        width: 100%;
        height: 100%;
        min-height: 80vh;
        overflow: hidden;
        display: flex;
        align-items: center;
        h1{
            color: rgb(255, 255, 255);
            margin: 20px auto 0;
            width: 200px;
            text-align: center;
        }
        #wrapper{
            position: absolute;
            width: 320px;
            left: calc(50% - 125px);
            top: calc(50% - 30px);
            margin-top: -75px;
        }

        /* === Sign in Form === */
        #signin {
            height: 90px;
            width: 300px;
            border-radius: 8px;
            position: relative;
        }
        #signin::before {
            display: block;
            position: relative;
            height: 2px;
            background: rgb(52, 56, 61);
            content: '';
            top: 44px;
            margin-left: 20px;
            z-index: 1;
        }
        #signin input:first-of-type{
            border-top-right-radius: 8px;
            border-top-left-radius: 8px;
        }
        #signin input:last-of-type{
            border-bottom-right-radius: 8px;
            border-bottom-left-radius: 8px;
        }
        #signin  input[type="text"], #signin  input[type="password"], #signin button[type="submit"]{
            background: rgb(28, 30, 33);
            box-shadow: inset -100px -100px 0 rgb(28, 30, 33); /*Prevent yellow autofill color*/
            color: rgb(52, 56, 61);
        }
        #signin  input[type="text"], #signin  input[type="password"]{
            position: relative;
            display: block;
            width: 280px;
            height: 45px;
            border: 0;
            outline: 0;
            top: -2px;
            padding: 0 0 0 20px;
            font-weight: 700;
        }
        #signin  input[type="text"]:focus, #signin  input[type="password"]:focus{
            color: rgb(255, 255, 255);
        }
        #signin button[type="submit"]{
            display: block;
            position: absolute;
            width: 52px;
            height: 52px;
            color: rgb(52, 56, 61);
            border-radius: 50px;
            outline: 0;
            z-index: 2;
            top: 19px;
            right: -24px;
            border: 6px solid rgb(52, 56, 61);
            font-size: 25px;
            text-indent: 0px;
            padding-left: 9px;
            padding-bottom: 3px;
            text-align: center;
        }
        #signin button[type="submit"]:hover{
            color: rgb(0, 126, 165);
            text-shadow: 0 0 10px rgb(0, 126, 165);
            cursor: pointer;
        }
        #signin p {
            color: rgb(79, 85, 97);
            padding: 0 20px;
            font-weight: 700;
            font-size: 12px;
            margin: 5px 0 0 0;
        }
        #signin p > a{
            color: rgb(111, 119, 135);
            text-decoration: none;
        }
        #signin p > a:hover{
            border-bottom: 1px solid;
        }
    }
</style>
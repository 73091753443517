<template>
    <main class="main-page">
        <section class="want-teach">
          <Sceleton v-if="!getDatabase"/>
            <div class="container" v-if="getDatabase">
                <h2 class="want-teach__title">ХОЧУ НАУЧИТЬ РЕБЕНКА</h2>
              <div class="navigation-btn-slider">
                <div class="teach-tab-ctrls">
                  <VueSlickCarousel ref="carousel" class="carousel" :arrows="true" :rows="3" :slidesToShow="3" :dots="true">
                    <router-link :to="{name: 'Courses', params: {id: point.id}}" class="teach-tab-ctrls__item teach-btn"
                                 v-for="(point, i) in getDatabase.points"
                                 :key="'courses-' + i + Math.floor(Math.random() * 99999)"
                                 :class="{'is-active':point.id === getRouterIdCourses}"
                                 @focusout="point.name = $event.target.innerText"
                                 :title="isAUTH && 'Чтобы редактировать кликните дважды'"
                    >
                      <span @dblclick="edit" @focusout="point.name = $event.target.innerText">
                        {{point.name}}
                      </span>
                      <button
                        v-if="isAUTH"
                        :title="isAUTH && 'Удалить'"
                        @click="removeItem(i, point.id)"
                      >
                        <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                          <path fill="currentColor" d="M19,4H15.5L14.5,3H9.5L8.5,4H5V6H19M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19Z" />
                        </svg>
                      </button>
                    </router-link>
                  </VueSlickCarousel>
                </div>
                <button @click="showPrev" class="prev">
                  <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M6,18V6H8V18H6M9.5,12L18,6V18L9.5,12Z" />
                  </svg>
                </button>
                <button @click="showNext" class="next">
                  <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M16,18H18V6H16M6,18L14.5,12L6,6V18Z" />
                  </svg>
                </button>
              </div>
              <slot
                  v-for="(point, i) in getDatabase.points"
              >
                <div class="teach-tab-item"
                     v-if="point.id === getRouterIdCourses"
                     :key="'courses-' + i + point.id"
                >
                  <div class="teach-tab-item__top">
                    <p class="teach-tab-item__top-title"
                       @click="edit"
                       @focusout="point.content.title = $event.target.innerText"
                    >{{point.content.title}}</p>
                    <div class="teach-tab-item__top-content">
                      <div class="teach-tab-item__top-iframe default-iframe">
                        <div class="courses-image-container">
                          <div
                            v-if="point.image"
                            :style="{
                              background: `url(${point.image})`,
                              backgroundPosition: 'center',
                              backgroundRepeat: 'no-repeat'
                            }"
                            class="default-iframe__poster"
                          ></div>
                          <img v-else class="default-iframe__poster" src="@/assets/images/courses/005.jpg" alt="">
                          <label v-if="isAUTH">
                            <span v-if="!changeImageLoader">Изменить</span>
                            <LoadsSpinner v-if="changeImageLoader" small/>
                            <input @change="upload($event, i, point)" hidden type="file">
                          </label>
                        </div>
                        <div class="iframe-container">
                          <label v-if="isAUTH">
                            <span v-if="!changeYouTube" @click="changeYouTube = !changeYouTube">Изменить</span>
                            <input v-if="changeYouTube" v-model="point.youtube"><span v-if="changeYouTube" @click="changeYouTubeVideo(point, i)" class="confirm">ок</span>
                          </label>
                          <iframe
                            v-if="point.youtube"
                            width="560"
                            height="315"
                            :src="point.youtube"
                            title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen></iframe>
                          <img width="100%" src="../assets/images/youtube-logo2-min.jpg" v-else-if="!point.youtube && isAUTH" alt="">
                        </div>
                      </div>
                      <div class="teach-tab-item__top-txt">
                        <p
                            @click="edit"
                            @focusout="point.content.text.title = $event.target.innerText"
                            v-html="point.content.text.title"
                        ></p>
                        <div class="teach-tab-item__top-txt-title"
                             @click="edit"
                             @focusout="point.content.text.points.title = $event.target.innerText"
                             v-html="point.content.text.points.title"
                        ></div>
                        <p
                            @click="edit"
                            @focusout="point.content.text.points.text = $event.target.innerText"
                            v-html="point.content.text.points.text"
                        >{{point.content.text.points.text}}</p>
                      </div>
                    </div>
                    <div class="courses-catalog">
                      <div class="courses-card"
                           v-for="(el, i) in point.priceContent"
                           :key="'courses-priceContent' + i"
                      >
                        <h4 class="courses-card__title"
                            @click="edit"
                            @focusout="el.title = $event.target.innerText"
                        >{{el.title}}</h4>
                        <div class="courses-card__txt"> <img src="@/assets/images/courses/coin.svg" alt="">
                          <p
                              @click="edit"
                              @focusout="el.subtitle = $event.target.innerText"
                          >{{el.subtitle}}</p>
                        </div>
                        <div class="courses-card__price-row">
                          <div class="courses-card__price">
                                        <span class="number"
                                              @click="edit"
                                              @focusout="el.price = $event.target.innerText"
                                        >{{el.price}}</span> руб</div>
                          <router-link class="btn" v-if="el.button === 'details'" :to="{path: '/start', hash: 'ya1'}">
                            <span class="btn__inner">Подробнее</span>
                          </router-link>
                          <div
                            v-else
                            class="btn"
                            :class="{'btn-disable':el === point.priceContent[0]}"
                            @click="modal = true, form.courses = el.title, form.price = el.price">
                            <span class="btn__inner">{{ el === point.priceContent[0] ? 'Нет мест' : 'Купить' }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </slot>
            </div>
        </section>
      <Modal
        v-show="modal"
        @close="modal = false"
        :isClose="true"
      >
        <template v-slot:body>
          <div style="
                    color: #3ECF31;
                    font-weight: 800;
                    font-size: 28px;
                    line-height: 33px;
                    margin-bottom: 20px;
                ">Специальная цена</div>
          <div style="color: #FFFFFF;margin-bottom: 20px">
            <span style="opacity: 0.6;font-feature-settings: 'pnum' on, 'lnum' on;">23 300</span>
            <span style="font-size: 28px;font-feature-settings: 'pnum' on, 'lnum' on;margin: 0 10px">16 000</span>
            <span style="opacity: 0.6;font-feature-settings: 'pnum' on, 'lnum' on;">+ 1 ребёнок бесплатно</span>
          </div>
          <div class="modal-buy" style="display: flex;flex-direction: column">
            <input v-model="form.name" style="padding: 16px 13px; margin-bottom: 12px;font-size: 18px; background: white;" placeholder="Имя" type="text">
            <input v-model="form.city" style="padding: 16px 13px; margin-bottom: 12px;font-size: 18px; background: white;" placeholder="Город" type="text">
            <input v-model="form.phone" style="padding: 16px 13px; margin-bottom: 12px;font-size: 18px; background: white;" placeholder="Телефон" type="text">
            <input v-model="form.email" style="padding: 16px 13px; margin-bottom: 12px;font-size: 18px; background: white;" placeholder="E-mail" type="text">
          </div>
          <div class="btn" @click="sendForm" style="text-align: center;cursor: pointer">Купить курс</div>
        </template>
      </Modal>
      <Modal
        v-show="modalShare"
        @close="modalShare = false"
        :isClose="true"
      >
        <template v-slot:body>
          <h5 class="modal-status">Заявка успешно отправлена!</h5>
          <h2 class="box-modal__title">Поделиться</h2>
          <p class="box-modal__txt">Мой ребенок записан на бесплатный урок по предпринимательству в школу KidStart</p>
          <div class="box-modal__soc">
            <a target="_blank" href="https://vk.com/share.php?url=https://kidstart.online"><img src="@/assets/images/modal/vk.svg"></a>
            <a target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=https://kidstart.online"><img src="@/assets/images/modal/fb.svg"></a>
            <a target="_blank" href="https://twitter.com/intent/tweet?url=https://kidstart.online&text=Мой ребенок записан на бесплатный урок по предпринимательству в школу KidStart"><img src="@/assets/images/modal/tw.svg"></a>
            <a target="_blank" href="https://connect.ok.ru/offer?url=https://kidstart.online&title=KidStart&description=Мой ребенок записан на бесплатный урок по предпринимательству в школу KidStart"><img src="@/assets/images/modal/ok.svg"></a>
          </div>
          <p class="box-modal__txt-gray">Кстати, за этот пост Вы получите
            <span>50% скидку</span> если ребенок будет учиться у нас дальше! Не забудьте
            сделать скриншот или фото после размещения и сохранить ссылку!
          </p>
        </template>
      </Modal>
    </main>
</template>

<script>
    import {mapGetters} from "vuex";
    import { getStorage, ref, uploadBytesResumable, getDownloadURL, deleteObject } from "firebase/storage";
    import readDB from '../components/read.js'
    import VueSlickCarousel from 'vue-slick-carousel'
    import 'vue-slick-carousel/dist/vue-slick-carousel.css'
    // optional style for arrows & dots
    import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
    import Modal from "../components/Modal";
    import axios from "axios";
    import querystring from "querystring";
    import LoadsSpinner from "../components/Loads-spinner";
    import Sceleton from "../components/Sceleton";
    export default {
      name: "Courses",
      components: {LoadsSpinner, Modal, VueSlickCarousel, Sceleton },
      data () {
          return {
            idCourses: null,
            modal: false,
            modalShare: false,
            notification: [],
            form: {
              name: null,
              email: null,
              phone: null,
              message: null,
              city: null,
              price: null,
              courses: null
            },
            changeImageLoader: false,
            changeYouTube: false
          }
      },
      computed: {
          ...mapGetters([
              'isAUTH'
          ]),
        getRouterIdCourses () {
          return (Number(this.$route.path.replace(/\/courses\//g, '')))
        },
          getDatabase () {
              return this.$root.database.courses
          }
      },
      methods: {
        edit (val) {
            if (this.isAUTH) {
                val.target.contentEditable = 'true'
                val.target.focus()
            } else {
                return false
            }
        },
        removeItem (index, itemId) {
          this.getDatabase.points[index].id === itemId && this.getDatabase.points.splice(index, 1)
        },
        showNext() {
          this.$refs.carousel.next()
        },
        showPrev() {
          this.$refs.carousel.prev()
        },
        sendForm(e) {
          e.preventDefault()
          this.form.message = this.$route.path + ` ... ${this.form.courses}; стоимость -  ${this.form.price}`
          this.modalShare = false
          axios.post('https://kidstart.online/send.php',
            querystring.stringify(this.form)).then(res => {
            res.status === 200 ? this.modalShare = true : alert('Что-то пошло не так =( \n Обновите страницу')
          })
        },
        async upload(event, index, element) {
          this.changeImageLoader = true
          const file = event.currentTarget.files[0]
          const storage = getStorage();

          // Create the file metadata
          /** @type {any} */
          const metadata = {
            contentType: 'image/jpeg'
          };

          // Upload file and metadata to the object 'images/mountains.jpg'
          const storageRef = ref(storage, 'images/' + Math.floor(9 + Math.random() * (9999 + 1 - 5)) + file.name);
          const uploadTask = uploadBytesResumable(storageRef, file, metadata);

          // Listen for state changes, errors, and completion of the upload.
          uploadTask.on('state_changed',
            (snapshot) => {
              // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
              const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              console.log('Upload is ' + progress + '% done');
              switch (snapshot.state) {
                case 'paused':
                  console.log('Upload is paused');
                  break;
                case 'running':
                  console.log('Upload is running');
                  break;
              }
            },
            (error) => {
              // A full list of error codes is available at
              // https://firebase.google.com/docs/storage/web/handle-errors
              switch (error.code) {
                case 'storage/unauthorized':
                  // User doesn't have permission to access the object
                  break;
                case 'storage/canceled':
                  // User canceled the upload
                  break;

                // ...

                case 'storage/unknown':
                  // Unknown error occurred, inspect error.serverResponse
                  break;
              }
            },
            () => {
              // Upload completed successfully, now we can get the download URL
              getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
                if (this.getDatabase.points[index].image) {
                  deleteObject(ref(storage, this.getDatabase.points[index].image)).then(() => {
                    console.log('File deleted successfully')
                  }).catch(() => {
                    console.log('Uh-oh, an error occurred!')
                  });
                  this.getDatabase.points[index].image = downloadURL
                  await readDB.writePointsData(downloadURL, index, element)
                } else {
                  await readDB.writePointsData(downloadURL, index, element)
                }
              });

              this.changeImageLoader = false
            }
          );
        },
        async changeYouTubeVideo (el, index) {
          const url = 'https://www.youtube.com/embed/' + el.youtube.replace('https://youtu.be/', '')
          await readDB.writeYouTubeUrl(url, index, el)
        }
      }
    }
</script>

<style scoped lang="scss">
    .teach-tab-item__top-iframe {
      .iframe-container {
        height: 100%;
        position: relative;
        margin-top: 20px;
        label {
          font-weight: bold;
          position: absolute;
          top: calc(50% - 20px);
          left: calc(50% - 50px);
          width: 100px;
          min-width: 100px;
          height: 40px;
          background: rgba(255, 255, 255, 0.6);
          border-radius: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 5px;
          cursor: pointer;
          z-index: 2;
          input {
            height: 30px;
            border-radius: 5px;
            font-size: 12px;
            padding-right: 38px;
          }
          .confirm {
            background: #3ECF31;
            height: 35px;
            padding: 5px 10px;
            transform: translate(-38px, -6px);
            border-radius: 0 5px 5px 0;
            color: white;
          }
          &:hover {
            background: rgba(255, 255, 255, 1);
          }
        }
        iframe {
          position: initial;
          @media (max-width: 1000px) {
            margin-top: 0;
          }
        }
        @media (max-width: 1000px) {
          margin-top: 0;
        }
      }
      @media (max-width: 1000px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 10px;
        grid-row-gap: 1em;
        max-height: 320px;
        max-width: 100%;
        margin: 0 auto;
        margin-bottom: 20px;
      }
      .default-iframe__poster {
        background-size: cover!important;
      }
    }
    .courses-image-container {
      position: relative;
      height: 100%;
      img {
        width: 100%;
      }
      label {
        font-weight: bold;
        position: absolute;
        top: calc(50% - 20px);
        left: calc(50% - 50px);
        width: 100px;
        min-width: 100px;
        height: 40px;
        background: rgba(255, 255, 255, 0.6);
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px;
        cursor: pointer;
        z-index: 2;
        &:hover {
          background: rgba(255, 255, 255, 1);
        }
      }
    }
    .teach-tab-ctrls {
      display: inherit;
      .teach-tab-ctrls__item {
        position: relative;
        button {
          position: absolute;
          right: 10px;
          width: 40px;
          height: 40px;
          top: calc(50% - 20px);
        }
      }
        .teach-btn {
            width: 208px;
            margin: 5px;
            @media (max-width: 1000px) {
                width: 350px;
            }
            @media (max-width: 780px) {
                width: 250px;
              font-size: 14px;
            }
            @media (max-width: 560px) {
                width: 150px;
              font-size: 12px;
            }
            @media (max-width: 480px) {
                width: 100%;
            }
        }
    }
    .navigation-btn-slider {
      position: relative;
      button {
        cursor: pointer;
        position: absolute;
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 30px;
        top: calc(50% - 42px);
        border: 1px solid #4169ee;
        opacity: 0.3;
        svg {
          color: #4169ee;
        }
        &:hover {
          background: #4169ee;
          opacity: 1;
          svg {
            color: #ffffff;
          }
        }
        &.prev {
          left: 0;
        }
        &.next {
          right: 0;
        }
        @media (min-width: 1200px) {
          &.prev {
            left: -60px;
          }
          &.next {
            right: -60px;
          }
        }
      }
    }
    .carousel {
      .teach-tab-ctrls__item {
        display: flex!important;
        justify-content: center;
        align-items: center;
      }
    }
    .modal-buy {
      input {
        border: initial;
        border-bottom: 1px solid #ede7e7;
      }
    }
    .btn.btn-disable {
      opacity: 0.4;
      background: darkgrey;
      border: none;
      pointer-events: none;
    }
    .teach-tab-item__top-txt-title, .teach-tab-item__top-txt a {
      color: #9974d5!important;
    }
    .courses-catalog .courses-card:nth-child(3) .btn:hover {
      background: #FFFFFF;
      color: #9974d5;
    }
    .teach-tab-item__top-title {
      font-size: 30px;
      color: #9974d5;
      margin-bottom: 40px;
      line-height: 30px;
      @media (max-width: 500px) {
        font-size: 22px;
        margin-bottom: 20px;
        line-height: 25px;
      }
    }
</style>

<template>
  <transition name="modal">
    <div class="modal__wrapper" @click="$emit('close')">
      <div class="modal-content" @click.stop="">
        <!-- header -->
        <div class="modal-header" v-if="title">
          <span class="modal-title"> {{ title }} </span>
        </div>
        <span class="button-close" title="close" v-if="isClose" @click="$emit('close')"></span>
        <!-- body -->
        <div class="modal-body">
          <slot name="body">Default body</slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: false
    },
    isClose: {
      type: Boolean,
      required: true
    }
  },
  mounted () {
    document.body.addEventListener('keyup', e => {
      if (e.key === 'Escape') this.$emit('close')
    })
  },
  computed: {},
  methods: {}
}
</script>

<style lang="scss">
  //animations
  .modal-enter, .modal-leave-active {
    opacity: 0;
  }
  .modal-enter .modal-content,
  .modal-leave-active .modal-content {
    transform: scale(1.2);
  }
  .modal__wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    transition: opacity .2s ease;
    right: 0;
    z-index: 998;
    background-color: rgba(00,00,00,.48);
  }
  .modal-content {
    position: relative;
    max-width: 600px;
    padding: 20px 18px;
    background-color: #fff;
    border: 1px solid #dcdfe6;
    transition: all .2s ease;
    border-radius: 8px;
    z-index: 999;
    overflow: hidden;
    @media screen and (min-width: 900px) {
      min-width: 500px;
    }
  }
  .modal-header {
    display: flex;
    align-self: center;
    justify-content: space-between;
    padding-bottom: 20px;
    span {
      font-size: 24px;
    }
  }
  .button-close {
    position: absolute;
    right: 20px;
    top: 15px;
    cursor: pointer;
    width: 12px;
    height: 12px;
    background: url("../assets/images/icons/close.svg");
    transition: all .3s;
    &:hover {
      transform: scale(1.1);
    }
  }
  .modal-body {
    text-align: center;
    padding: 10px;
    img {
      width: 100% !important;
      max-width: initial!important;
      border-radius: 5px;
    }
  }
  .modal-bottom {
    padding-top: 40px;
  }
  a.another-modal {
    color: #000;
    border-bottom: 1px solid #000;
  }
  input {
    border: initial;
    border-bottom: 1px solid #ede7e7;
    padding: 16px 13px;
    margin-bottom: 12px;
    font-size: 18px;
    background: white;
  }
</style>
